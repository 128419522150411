import { BrowserRouter, Routes, Route } from "react-router-dom"
import App from "./App"
import GuestQrcodeComponent from "./components/GuestQrcodeComponent"
import GuestChangelangComponent from "./components/GuestChangelangComponent"
import MemberComponent from "./components/member/MemberComponent"
import LinkListComponent from "./components/member/LinkListComponent"
import DocComponent from "./components/member/DocComponent"
import ProfileComponent from "./components/member/ProfileComponent"
import DonateComponent from "./components/member/DonateComponent"
import LinkToComponent from "./components/LinkToComponent"

const MyRoute=()=>{
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" exact element={<App/>}></Route>
                <Route path="/qrcode" exact element={<GuestQrcodeComponent/>}></Route>
                <Route path="/changelang" exact element={<GuestChangelangComponent/>}></Route>
                <Route path="/member" exact element={<MemberComponent/>}></Route>
                <Route path="/member/linklist" exact element={<LinkListComponent/>}></Route>
                <Route path="/member/doc" exact element={<DocComponent/>}></Route>
                <Route path="/member/profile" exact element={<ProfileComponent/>}></Route>
                <Route path="/member/other" exact element={<DonateComponent/>}></Route>

                <Route path="*" exact element={<LinkToComponent/>}></Route>
            </Routes>
        </BrowserRouter>
    )
}

export default MyRoute;