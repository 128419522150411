import MemberNavbarComponent from "./MemberNavbarComponent";
import MemberHeaderComponent from "./MemberHeaderComponent";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getUserID, getUserEmail, getUserName, getToken } from "../../service/authorize";


const MemberComponent = (props) => {
    const [links,setLinks] = useState([]);
    const [state, setState] = useState({
        token: "",
        longUrl: "",
        shortUrl: "",
        qrCodeText: "",
        qrCodeImage: "",
        errorText: "",
        langChange: "",
        resultText: "",
    });

    const { token,longUrl,shortUrl,qrCodeText,qrCodeImage,errorText,langChange,resultText } = state;
    const [showCopyText, setShowCopyText] = useState(false);
    const [showCopyLinkText, setShowCopyLinkText] = useState(false);
    const [showCopyENTHText, setShowCopyENTHText] = useState(false); 
    
    const handleChange = (event) => {
        setState({...state,langChange:event.target.value});
    };
    
    const inputValue = name => event => {
        setState({ ...state, [name]: event.target.value });
    };

    const sendPostChangeLangForm = (e) => {
        e.preventDefault();
        console.log(errorText);
        console.log(langChange);
        axios
            .post(`${process.env.REACT_APP_API}member/returntext`, { text: errorText,type:langChange  },
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`
                    }
                })
            .then(response => {
                console.log(response.data.return)
                setState({...state, resultText:response.data.return});
            }).catch(err => {
                Swal.fire(
                    "Error",
                    "ERROR",
                    "error",
                );
            })
    };

    const currentLinkSet = () => {
        axios
        .post(`${process.env.REACT_APP_API}member/getlinklist`,{ start:0,end:5 },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        })
        .then(response=>{
            // console.log(response.data.return)
            if(response.data.return!==null&&response.data.return!==""&&response.data.return!==undefined)
            {
                setLinks(response.data.return);
            }

        }).catch(err=>{
          Swal.fire(
            "Error",
            err.response.data.error,
            "error",
          );
        })
    };

    const genNewToken = (e) => {
        e.preventDefault();
        axios
            .post(`${process.env.REACT_APP_API}auth/gennewtoken`, {  },
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`
                    }
                })
            .then(response => {
                if(response.data.success===1)
                {
                    Swal.fire(
                        "Success",
                        "New Token generate",
                        "success",
                    );
                    setState({...state, token:response.data.result.token});

                }
                else
                {
                    Swal.fire(
                        "Error",
                        "new Token not generate",
                        "error",
                    );
                }
                
            }).catch(err => {
                Swal.fire(
                    "Error",
                    "ERROR",
                    "error",
                );
            })
    };

    const copyText = () =>{
        navigator.clipboard.writeText(token);
        setShowCopyText(!showCopyText);
        setTimeout(
            function() {
                setShowCopyText(false);
            }
        ,3000)
    }

    const copyLinkText = () =>{
        navigator.clipboard.writeText(shortUrl);
        setShowCopyLinkText(!showCopyLinkText);
        setTimeout(
            function() {
                setShowCopyLinkText(false);
            }
        ,3000)
    }

    const copyENTHText = () =>{
        navigator.clipboard.writeText(resultText);
        setShowCopyENTHText(!showCopyENTHText);
        setTimeout(
            function() {
                setShowCopyENTHText(false);
            }
        ,3000)
    }
    
    const sendQrCode = (e) => {
        e.preventDefault();
        axios
        .post( `${process.env.REACT_APP_API}member/returnqrcode`, { text:qrCodeText },
            {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            }
        )
        .then(response=>{
            // console.log(response)
            setState({...state, qrCodeText:qrCodeText, qrCodeImage:response.data.return});
        }).catch(err=>{
            Swal.fire(
            "Error",
            err.response.data.error,
            "error",
            );
        })
    }

    
    const postShortLink = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API}member/shortlink`, { link: longUrl },
            {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            })
        .then(response => {
            // console.log(response);
            setState({...state, longUrl:longUrl, shortUrl:response.data.result.short_url});
            currentLinkSet();
        }).catch(err => {
            Swal.fire(
                "Error",
                // err.response.data.error,
                "ERROR",
                "error",
            );
        })
    };

    const navigate = useNavigate();
    useEffect(() => {
        if (!getToken()) {
            navigate("/");
        }
        setState({ ...state, id:  getUserID(), showName:  getUserName(),email:  getUserEmail(),token:  getToken() });
        currentLinkSet();
    }, []);

    return(
        <div className="bg-light">
            <MemberHeaderComponent />
            <div className="container-fluid" >
                <MemberNavbarComponent />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-5">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="h2">Dashboard</h1>
                        <div className="btn-toolbar mb-2 mb-md-0">

                        </div>
                    </div>
                    <div className="row ">
                        <label htmlFor="current_token" className="col-form-label col-md-1">Token : </label>
                        <div className="col-md-8">
                            <input className="form-control pointer_cursor" type="text" name="token" id="current_token"  value={token} readOnly onClick={copyText} />
                            {showCopyText && <span className="text-success"  style={{
                                transition: "right 1s ease-in-out",
                            }}>Copy Token to clipboard complete</span>}
                        </div>
                        <div className="col-md-3">
                            <input className="form-control btn btn-info" type="button" id="generate_token" value="Gen New Token" onClick={genNewToken} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-3 pt-3 pb-2 mb-3 border-bottom border-top">
                        <h4 className="h4">Link Genarate</h4>
                    </div>
                    <div className="row ">
                        <label htmlFor="long_url" className="col-form-label col-md-1">Long URL : </label>
                        <div className="col-md-4">
                            <input className="form-control pointer_text" type="text" name="long_url" id="long_url" value={longUrl} onChange={inputValue("longUrl")} required />
                        </div>
                        <label htmlFor="short_url" className="col-form-label col-md-1">Short URL : </label>
                        <div className="col-md-3">
                            <input className="form-control pointer_cursor" type="text" name="short_url" id="short_url" value={shortUrl} onClick={copyLinkText} readOnly  />
                            {showCopyLinkText && <span className="text-success"  style={{
                                transition: "right 1s ease-in-out",
                            }}>Copy Link to clipboard complete</span>}
                        </div>
                        <div className="col-md-3">
                            <input className="form-control btn btn-info" type="button" id="generate_short_link" value="Gen Short Link" onClick={postShortLink}  />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-3 pt-3 pb-2 mb-3 border-bottom border-top">
                        <h4 className="h4">Current Link</h4>
                    </div>
                    <div className="row" id="current_link">
                    {
                        links.map((link,index)=>(
                            
                            <div className="row" key={index} data-long={decodeURIComponent(link.long_url)} data-short={process.env.REACT_APP_URL+link.short_url}>
                                <div className="col-1">{(index+1)}</div>
                                <div className="col-6">Long Url {'=>'} <a target='_blank' href={decodeURIComponent(link.long_url)}>{decodeURIComponent(link.long_url)}</a></div>
                                <div className="col-5">Short Url {'=>'} <a target='_blank' href={process.env.REACT_APP_URL+link.short_url}>{process.env.REACT_APP_URL+link.short_url}</a></div>
                            </div>
                        ))
                    }
                    </div>

                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-3 pt-3 pb-2 mb-3 border-bottom border-top">
                        <h4 className="h4">QR Genarate</h4>
                    </div>
                    <div className="row">
                        <label htmlFor="text" className="col-3 col-form-label">Whataver Text: </label>
                        <div className="col-6">
                            <input type="text" className="form-control" name="text" id="text" value={qrCodeText} onChange={inputValue("qrCodeText")} />
                        </div>
                        <div className="col-3">
                            <input type="submit" className="btn btn-info w-100" id="genqrcode" value="Generate" onClick={sendQrCode} />
                        </div>
                    </div>
                    {qrCodeImage && (
                        <div className="row mt-5">
                            <div className="col-3 col-form-label">QRCode : </div>
                            <div id="qrcode"  className="col-6 col-form-label text-start">
                                <img src={qrCodeImage} />
                            </div>
                            <div className="col-3 text-left" id="btn-container">
                                <a href={qrCodeImage} download={'Qrcode_'+qrCodeText+'.png'} className='btn btn-block btn-success'>Export / Download</a>
                            </div>
                        </div>
                    )}

                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-3 pt-3 pb-2 mb-3 border-bottom border-top">
                        <h4 className="h4">EN? {'=>'} TH</h4>
                    </div>
                    <form onSubmit={sendPostChangeLangForm}>
                        <div className="row">  
                            <label htmlFor="maintxt" className="col-3 col-form-label">Error EN : </label>
                            <div className="col-6">
                                <textarea className="form-control" name="maintxt" id="maintxt" rows="5" onChange={inputValue("errorText")} ></textarea>
                            </div>
                            <div className="col-3">
                                <input type="submit" className="btn btn-info mb-2 w-100" id="enthgenerate" value="Use The Megic" />
                                <select className="form-select" id="type_change" value={langChange} onChange={handleChange.bind(this)}>
                                    <option value="0">Swap All</option>
                                    <option value="1">Only EN to TH </option>
                                    <option value="2">Only TH to EN</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mt-5">  
                            <label htmlFor="result" className="col-3 col-form-label">Result : </label>
                            <div className="col-6">
                                <textarea readOnly className="form-control pointer_cursor" name="result" id="result" rows="5" value={resultText} onClick={copyENTHText}></textarea>
                            </div>
                            {showCopyENTHText && <span className="text-success text-center"  style={{
                                transition: "right 1s ease-in-out",
                            }}>Copy Text to clipboard complete</span>}
                        </div>
                    </form>
                </main>
            </div>
        </div>
    )
}

export default MemberComponent;