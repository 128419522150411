import MemberNavbarComponent from "./MemberNavbarComponent";
import MemberHeaderComponent from "./MemberHeaderComponent";
import React, { useState, useEffect,useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { getUserID, getUserEmail, getUserName, getToken } from "../../service/authorize";





const ProfileComponent = (props) => {
  
    const firstRender = useRef(true);

    const [state, setState] = useState({
        id: "",
        showName: "",
        email: "",
        password: "",
        confirmPassword: ""
    });

    const { id, showName, email,password, confirmPassword } = state;

    const [passwordMatchError, setPasswordMatchError] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    
    const handleClick = () => {
        setIsVisible(!isVisible); // Toggle visibility
    };

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            setState({ ...state, id:  getUserID(), showName:  getUserName(),email:  getUserEmail() });
        }
    }, [state]);


    const inputValue = name => event => {
        setState({ ...state, [name]: event.target.value });
    };

    // 

    const handleConfirmPasswordChange = (event) => {
        setState({ ...state, confirmPassword: event.target.value });
        // Check password match immediately on change
        setPasswordMatchError(event.target.value !=="" && event.target.value !== password);
    };

    const changePasswordForm = (e) => {
        e.preventDefault();

        if(password!==confirmPassword)
        {
            Swal.fire(
                "Error",
                "Password not match",
                "error",
            );
            return false;
        }
        

        axios
        .post(`${process.env.REACT_APP_API}auth/changepassword`, { password, id },
        {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
        .then(response => {

            if(response.data.success===1)
            {
                Swal.fire(
                    "Success",
                    response.data.message,
                    "success",
                );
                setIsVisible(!isVisible); 
                setState({ ...state, password: '', confirmPassword: '' });
            }
            else
            {
                Swal.fire(
                    "Error",
                    response.data.message,
                    "error",
                );
            }

        }).catch(err => {
            Swal.fire(
                "Error",
                err.response.data.error,
                "error",
            );
        })
    };


    return(
        <div className="bg-light">
            <MemberHeaderComponent />
            <div className="container-fluid" >
                <MemberNavbarComponent />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="h2">Profile</h1>
                        <div className="btn-toolbar mb-2 mb-md-0">
                        </div>
                    </div>
                    <div className="row g-3 align-items-center mb-3">
                        <div className="col-md-2">
                        <label htmlFor="name" className="col-form-label">Name</label>
                        </div>
                        <div className="col-auto">
                        <input type="text" id="name" className="form-control readonly-bg" aria-describedby="name"  value={showName} readOnly />
                        </div>
                    </div>
                    <div className="row g-3 align-items-center mb-3">
                        <div className="col-md-2">
                        <label htmlFor="email" className="col-form-label">Email</label>
                        </div>
                        <div className="col-auto">
                        <input type="email" id="email" className="form-control readonly-bg" aria-describedby="email" value={email} readOnly />
                        </div>
                    </div>
                    {!isVisible && ( 
                        <input type="button" value="Change Password" id="changepass" className="btn btn-info mb-5"  onClick={handleClick}/>
                    )}
                    {isVisible && ( 
                        <form onSubmit={changePasswordForm}  className="mb-5">
                            <div className="row g-3 align-items-center mb-2">
                                <div className="col-md-2">
                                    <label htmlFor="password" className="col-form-label">Password</label>
                                </div>
                                <div className="col-auto">
                                    
                                    <input type="password" id="password" name="password" className="form-control form-inline" 
                                    aria-describedby="password"  value={password} 
                                    onChange={inputValue("password")} />
                                </div>
                            </div>
                            <div className="row g-3 align-items-center mb-3">
                                <div className="col-md-2">
                                    <label htmlFor="confirm_password" className="col-form-label">Confirm Password</label>
                                </div>
                                <div className="col-auto">
                                    <input type="password" id="confirm_password" name="confirm_password" className="form-control form-inline" 
                                    aria-describedby="password" value={confirmPassword} onChange={handleConfirmPasswordChange}/>
                                </div>
                                {passwordMatchError && <p className="error-message">Passwords do not match.</p>}
                            </div>
                            <div className="row g-3 align-items-center mb-3">
                                <div className="col-auto">
                                    <input type="submit" value="Change Password" id="submitchangepass" className="btn btn-info mb-5" />
                                </div>
                            </div>
                        </form>
                    )}
                </main>
            </div>
        </div>
    )
}

export default ProfileComponent;