import NavbarComponent from "../components/NavbarComponent";
import LoginComponent from "../components/LoginComponent";
import FooterComponent from "../components/FooterComponent";
import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";

const GuestChangelangComponent = (props) => {

    const [state, setState] = useState({
        generate: "",
        result: "",
        langChange: "",
    });
    const { generate, result, langChange } = state;

    
    // update state by name
    const inputValue=name=>event=>{
        setState({...state,[name]:event.target.value});
    };

    const handleChange = (event) => {
        setState({...state,langChange:event.target.value});
    };

    const sendPostChangeLangForm = (e) => {
        e.preventDefault();
        axios
            .post(`${process.env.REACT_APP_API}guest/returntext`, { text: generate,type:langChange  },
                {
                    headers: {
                        Authorization: `Bearer `
                    }
                })
            .then(response => {
                // Swal.fire(
                //     "Complete",
                //     "URL Complete",
                //     "success",
                // );
                console.log(response.data);
                setState({...state, result:response.data.return});
            }).catch(err => {
                Swal.fire(
                    "Error",
                    // err.response.data.error,
                    "ERROR",
                    "error",
                );
            })
    };

    return (
        <div className="d-flex h-100 bg-dark">
            <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column text-center text-white min-h-max">
                <NavbarComponent />
                <div className="container p-5">
                    <main className="px-3">
                        <form onSubmit={sendPostChangeLangForm}>
                            <div className="row">  
                                <label htmlFor="maintxt" className="col-3 col-form-label">Error EN : </label>
                                <div className="col-6">
                                    <textarea  className="form-control" name="maintxt" id="maintxt" onChange={inputValue("generate")} value={generate}></textarea>
                                </div>
                                <div className="col-3">
                                    <input type="submit" className="btn btn-info" id="generate" value="Use the megic" />
                                </div>
                            </div>
                            <div className="row mt-5">  
                                <label htmlFor="result" className="col-3 col-form-label">Result : </label>
                                <div className="col-6">
                                    <textarea readOnly className="form-control" name="result" id="result" value={result}></textarea>
                                </div>
                                <div className="col-3">
                                    <select className="form-select" id="type_change" value={langChange} onChange={handleChange.bind(this)}>
                                        <option value="0">Swap All</option>
                                        <option value="1">Only EN to TH </option>
                                        <option value="2">Only TH to EN</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </main>
                </div>
                <FooterComponent />
            </div>
            <LoginComponent />
        </div>
    );
}

export default GuestChangelangComponent;