import NavbarComponent from "../components/NavbarComponent";
import LoginComponent from "../components/LoginComponent";
import FooterComponent from "../components/FooterComponent";
import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";

const GuestQrcodeComponent=(props)=>{

    const [state, setState] = useState({
        whatever_text: "",
        qrimg: "",
    });
    const { whatever_text, qrimg } = state;

    
    // update state by name
    const inputValue=name=>event=>{
        setState({...state,[name]:event.target.value});
    };


    const sendQrCode= (e) => {
        e.preventDefault();

        axios
        .get( `${process.env.REACT_APP_API}guest/returnqrcode?text=${whatever_text}`,
            {
                headers: {
                    Authorization: `Bearer `
                }
            }
        )
        .then(response=>{
            // console.log(response)
            setState({...state, whatever_text:whatever_text, qrimg:response.data.return});
        }).catch(err=>{
            Swal.fire(
            "Error",
            err.response.data.error,
            "error",
            );
        })
    }

    return (
        <div className="d-flex h-100 bg-dark">
            <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column text-center text-white min-h-max">
                <NavbarComponent />
                <div className="container p-5">
                    <main className="px-3">
                        <div className="row">
                            <label htmlFor="text" className="col-3 col-form-label">Whataver Text: </label>
                            <div className="col-6">
                                <input type="text" className="form-control" name="text" id="text" value={whatever_text} onChange={inputValue("whatever_text")} />
                            </div>
                            <div className="col-3">
                                <input type="submit" className="btn btn-info" id="genqrcode" value="Generate" onClick={sendQrCode} />
                            </div>
                        </div>
                        <div className="row mt-5">
                            <label htmlFor="qrcode" className="col-3 col-form-label">QRCode : </label>
                            <div id="qrcode" className="col-9 col-form-label text-start">
                                <img src={qrimg} />
                            </div>
                        </div>
                        {qrimg && (
                            <div className="row mt-5">
                                <div className="col-md-3"></div>
                                <div className="col-md-6 text-left d-grid" id="btn-container">
                                    <a href={qrimg} download={'Qrcode_'+whatever_text+'.png'} className='btn btn-block btn-success'>Export / Download</a>
                                </div>
                                <div className="col-md-6"></div>
                            </div>
                        )}
         
                    </main>
                </div>
                <FooterComponent />
            </div>
            <LoginComponent />
        </div>
    );
}

export default GuestQrcodeComponent;