import NavbarComponent from "./components/NavbarComponent";
import LoginComponent from "./components/LoginComponent";
import FooterComponent from "./components/FooterComponent";
import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';

function App() {
    // const navigate = useNavigate();
    // prepare state
    const [state, setState] = useState({
        longUrl: "",
        shortUrl: "",
    });
    const { longUrl, shortUrl } = state;

    
    // update state by name
    const inputValue=name=>event=>{
        setState({...state,[name]:event.target.value});
    };

    const sendPostForm = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API}guest/shortlink`, { link: longUrl },
            {
                headers: {
                    Authorization: `Bearer `
                }
            })
        .then(response => {
            // Swal.fire(
            //     "Complete",
            //     "URL Complete",
            //     "success",
            // );
            console.log(response);
            setState({...state, longUrl:longUrl, shortUrl:response.data.result.short_url});
        }).catch(err => {
            Swal.fire(
                "Error",
                // err.response.data.error,
                "ERROR",
                "error",
            );
        })
    };

    return (
        <div className="d-flex h-100 bg-dark">
            <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column text-center text-white min-h-max">
                <NavbarComponent />
                <div className="container p-5">
                    <main className="px-3">
                        <form onSubmit={sendPostForm}>
                            <div className="row">
                                <label htmlFor="long_url" className="col-3 col-form-label">Your Url : </label>
                                <div className="col-6">
                                    <input type="text" className="form-control" name="long_url" id="long_url" value={longUrl} onChange={inputValue("longUrl")} />
                                </div>
                                <div className="col-3">
                                    <input type="submit" className="btn btn-info" id="genshortlink" value="Generate" />
                                </div>
                            </div>
                            <div className="row mt-5">
                                <label htmlFor="long_url" className="col-3 col-form-label">Short URL : </label>
                                <label id="short_url" className="col-9 col-form-label text-start">
                                    <a target="_blank" rel="noreferrer" href={shortUrl}>{shortUrl}</a>
                                </label>
                            </div>
                        </form>
                    </main>
                </div>
                <FooterComponent />
            </div>
            <LoginComponent />
        </div>
    );
}

export default App;
