export const authenticate=(response,next)=>{
    if(window !== "undefined"){
        // collect data to session storage
        sessionStorage.setItem("thsl_token",JSON.stringify(response.data.result.token));
        sessionStorage.setItem("thsl_id",JSON.stringify(response.data.result.id));
        sessionStorage.setItem("thsl_user",JSON.stringify(response.data.result.name));
        sessionStorage.setItem("thsl_email",JSON.stringify(response.data.result.email));
        // console.log(sessionStorage)
    }
    next();
}

//get token data
export const getToken=()=>{
    if(window !== "undefined"){
        if(sessionStorage.getItem("thsl_token")){
            return JSON.parse(sessionStorage.getItem("thsl_token"));
        }else{
            return false;
        }
    }
}

//get name data
export const getUserName=()=>{
    if(window !== "undefined"){
        if(sessionStorage.getItem("thsl_user")){
            return JSON.parse(sessionStorage.getItem("thsl_user"));
        }else{
            return false;
        }
    }
}

//get email data
export const getUserEmail=()=>{
    if(window !== "undefined"){
        if(sessionStorage.getItem("thsl_email")){
            return JSON.parse(sessionStorage.getItem("thsl_email"));
        }else{
            return false;
        }
    }
}

//get id data
export const getUserID=()=>{
    if(window !== "undefined"){
        if(sessionStorage.getItem("thsl_id")){
            return JSON.parse(sessionStorage.getItem("thsl_id"));
        }else{
            return false;
        }
    }
}

export const logout=(next)=>{
    if(window !== "undefined"){
        sessionStorage.removeItem("thsl_token");
        sessionStorage.removeItem("thsl_user");
        sessionStorage.removeItem("thsl_email");
        sessionStorage.removeItem("thsl_id");
    }
    next();
}