import MemberNavbarComponent from "./MemberNavbarComponent";
import MemberHeaderComponent from "./MemberHeaderComponent";
import React from "react";
import postmanCreatLinkImg from '../../assets/images/shortlinkmember.jpg';
import loginImg from '../../assets/images/loginandgettokenmember.jpg';
import resultQrCodeImg from '../../assets/images/qrcodemember.jpg';
import resultENTHImg from '../../assets/images/returntextmember.jpg';

const DocComponent = (props) => {

    return(
        <div className="bg-light">
            <MemberHeaderComponent />
            <div className="container-fluid" >
                <MemberNavbarComponent />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="h2">Document</h1>
                        <div className="btn-toolbar mb-2 mb-md-0">
                        </div>
                    </div>
                    <div className="row" id="gettokenpage">
                        <div className="col-12  mb-5">
                            <h2>Get Token</h2>
                        </div>
                        <div className="col-12">
                            <h3>Postman/Thunder Client</h3>
                        </div>
                        <div className="col-12">
                            <img src={ loginImg } className="img-fluid" />
                        </div>
                        <div className="col-12">
                            <hr />
                        </div>
                    </div>
                    <div className="row" id="shortlinkpage">
                        <div className="col-12  mb-5">
                            <h2>Short Link</h2>
                        </div>
                        {/* <div className="col-12">
                            <h3>PHP Curl</h3>
                        </div>
                        <div className="col-12 boxcode" >
                            $ch = curl_init(); <br /> 
                            $yourtoken = "xxxxxxxxxxx";<br /> 
                            $yourlink = "https://www.youtube.com"; <br /> 
                            curl_setopt($ch, CURLOPT_URL,"https://thsl.xyz/api/createlink.php");<br />
                            curl_setopt($ch, CURLOPT_POST, 1);<br /> 
                            curl_setopt($ch, CURLOPT_POSTFIELDS,"key=".$yourtoken."&link=".$yourlink);<br /> 
                            curl_setopt($ch, CURLOPT_RETURNTRANSFER, true); <br /> 
                            $server_output = curl_exec($ch); <br /> 
                            curl_close ($ch); <br />  
                        </div>
                        <div className="col-12">
                            <h3>Result</h3>
                        </div>
                        <div className="col-12 boxcode" >
                            Result return with json like : <br />
                            {'{'}"success":1,"msg":"create new link","data":{'{'}"short_url":"https:\/\/thsl.xyz\/xxx","long_url":"https:\/\/www.youtube.com"{'}}'}
                        </div>
                        <div className="col-12">
                            <hr />
                        </div> */}
                        <div className="col-12">
                            <h3>Postman/Thunder Client</h3>
                        </div>
                        <div className="col-12">
                            <img src={ postmanCreatLinkImg } className="img-fluid" />
                        </div>
                        <div className="col-12">
                            <hr />
                        </div>
                    </div>
                    <div className="row" id="qrcodepage">
                        <div className="col-12 mb-5">
                            <h2>QR Code</h2>
                        </div>
                        <div className="col-12">
                            <h3>Postman / Thunder Client</h3>
                        </div>
                        <div className="col-12">
                            <img src={resultQrCodeImg} className="img-fluid" />
                        </div>
                        <div className="col-12">
                            <hr />
                        </div>
                    </div>
                    <div className="row" id="qrcodepage">
                        <div className="col-12 mb-5">
                            <h2>EN? {'->'} TH</h2>
                        </div>
                        <div className="col-12">
                        <h3>Postman / Thunder Client</h3>
                        </div>
                        <div className="col-12">
                            <img src={resultENTHImg} className="img-fluid" />
                        </div>
                        <div className="col-12">
                            <hr />
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default DocComponent;