import MemberNavbarComponent from "./MemberNavbarComponent";
import MemberHeaderComponent from "./MemberHeaderComponent";
import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";

const DonateComponent = (props) => {
    
    const [state, setState] = useState({
        amount: "",
        qrimg: "",
    });
    const { amount, qrimg } = state;

    
    // update state by name
    const inputValue=name=>event=>{
        setState({...state,[name]:event.target.value});
    };


    const genPrompPay= (e) => {
        e.preventDefault();
        setState({...state,qrimg:""});
        axios
        .post( `${process.env.REACT_APP_API}member/promppay_gen`, { amount },
            {
                headers: {
                    Authorization: `Bearer `
                }
            }
        )
        .then(response=>{
            const qrPath = `${process.env.REACT_APP_API}`+response.data.return;
            setState({...state, amount:amount, qrimg:qrPath});
        }).catch(err=>{
            Swal.fire(
            "Error",
            err.response.data.error,
            "error",
            );
        })
    }

    return(
        <div className="bg-light">
            <MemberHeaderComponent />
            <div className="container-fluid" >
                <MemberNavbarComponent />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-5">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="h2">Give me some coffee</h1>
                        <div className="btn-toolbar mb-2 mb-md-0">
                        </div>
                    </div>
                    <div className="row" id="cofee">
                        <form onSubmit={genPrompPay}>
                            <div className="row ">
                                <label className="col-form-label col-md-1">Amount(THB) : </label>
                                <div className="col-md-4">
                                    <input className="form-control pointer_text" type="text" name="amount" id="amount" value={amount} onChange={inputValue("amount")} />
                                </div>
                                <div className="col-md-3">
                                    <input className="form-control btn btn-info" type="submit" id="promtpay_generate" value="Gen QRCODE" />
                                </div>
                            </div>
                        </form>
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-3 pt-3 pb-2 mb-3 border-bottom border-top">
                            <h4 className="h4">Promt Pay QR Code</h4>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div  id="promtpayqrcode">
                                    {qrimg!="" && <img src={qrimg} alt="DonateQrCode"></img> }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default DonateComponent;