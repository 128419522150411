import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { authenticate, getUserName } from "../service/authorize";
import { useNavigate } from "react-router-dom";
//useNavigate replace withRouter

const LoginComponent = (props) => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        username: "",
        password: "",
        registerName: "",
        registerEmail: "",
        registerPassword: "",
        registerConfirmPassword: "",
    });
    const { username, password,registerName, registerEmail,registerPassword, registerConfirmPassword } = state;
    const [passwordMatchError, setPasswordMatchError] = useState(false);

    useEffect(() => {
        if (getUserName()) {
            navigate("/member");
        }
    }, []);

    const handleConfirmPasswordChange = (event) => {
        setState({ ...state, registerConfirmPassword: event.target.value });
        // Check password match immediately on change
        setPasswordMatchError(event.target.value !=="" && event.target.value !== registerPassword);
    };

    const inputValue = name => event => {
        setState({ ...state, [name]: event.target.value });
    };

    const loginFrom = (e) => {
        e.preventDefault();
        axios
        .post(`${process.env.REACT_APP_API}auth/login`, { username, password })
        .then(response => {

            if(response.data.success===1)
            {
   
                document.querySelector('.btn-close').click();
                authenticate(response, () => navigate("/member"));
            }
            else
            {
                Swal.fire(
                    "Error",
                    response.data.message,
                    "error",
                );
            }

        }).catch(err => {
            Swal.fire(
                "Error",
                err.response.data.error,
                "error",
            );
        })
    };

    const signupFrom = (e) => {
        e.preventDefault();
        if(registerPassword!==registerConfirmPassword)
        {
            Swal.fire(
                "Error",
                "Password not match",
                "error",
            );
            return false;
        }
        
        axios
        .post(`${process.env.REACT_APP_API}auth/register`, { name:registerName, email:registerEmail, password:registerPassword })
        .then(response => {
            Swal.fire({
                title: "Success",
                text: "Register Complete Please Login",
                icon: "success",
            });

            document.querySelector("#loginmodalbut").click();
        }).catch(err => {
            console.log(err)
            Swal.fire(
                "Error",
                err.response.data.error,
                "error",
            );
        })
    };

    return (
        <div>
            <div className="modal fade" id="loginForm" tabIndex="-1" aria-labelledby="loginmodal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="loginmodal">Login Form</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={loginFrom}>
                                <div className="mb-3">
                                    <label className="form-label">Email Address</label>
                                    <input type="email" className="form-control" id="email" name="email" placeholder="Email" value={username} onChange={inputValue("username")} required />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Password</label>
                                    <input type="password" className="form-control" id="password" name="password" placeholder="Password" value={password} onChange={inputValue("password")} required />
                                </div>
                                <div className="modal-footer d-block">
                                    <p className="float-start">Not yet account <a href="#" data-bs-toggle="modal" id="signupmodalbut" data-bs-target="#signupForm">Sign Up</a></p>
                                    <button type="submit" className="btn btn-warning float-end">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="signupForm" tabIndex="-1" aria-labelledby="signupmodal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="signupmodal">Create Your Account</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={signupFrom}>
                                <div className="mb-3">
                                    <label className="form-label">Name</label>
                                    <input type="text" className="form-control" id="regis_name" name="name" placeholder="Enter Name" value={registerName} onChange={inputValue("registerName")}  required />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Email Address</label>
                                    <input type="email" className="form-control" id="regis_email" name="email" placeholder="Enter Email" value={registerEmail} onChange={inputValue("registerEmail")}  required />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Password</label>
                                    <input type="password" className="form-control" id="regis_password" name="password" placeholder="Enter Password" 
                                        value={registerPassword} 
                                        onChange={inputValue("registerPassword")}  required 
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Confirm Password</label>
                                    <input type="password" className="form-control" id="regis_confirmpassword" name="confirmpassword" 
                                        placeholder="Confirm Password" 
                                        value={registerConfirmPassword} onChange={handleConfirmPasswordChange}  required 
                                    />
                                    {passwordMatchError && <p className="error-message">Passwords do not match.</p>}
                                </div>
                                <div className="modal-footer d-block">
                                    <p className="float-start">OR <a href="#" data-bs-toggle="modal" id="loginmodalbut" data-bs-target="#loginForm">Login</a></p>
                                    <button type="submit" className="btn btn-warning float-end">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginComponent;