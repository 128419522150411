import {Link,useLocation} from "react-router-dom";

const NavbarComponent=()=>{
    // const navigate = useNavigate();
    const location = useLocation();
    return(
        <div className="row">
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                <div className="position-sticky pt-3">
                    <ul className="nav flex-column">
                        
                        <li className="nav-item">
                            <Link to="/member" className={location.pathname === '/member' ? 'nav-link active' : 'nav-link'} aria-current="page">
                                <span data-feather="home"></span> Dashboard
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/member/linklist" className={location.pathname === '/member/linklist' ? 'nav-link active' : 'nav-link'} aria-current="page">
                                <span data-feather="file"></span> Link List
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/member/doc" className={location.pathname === '/member/doc' ? 'nav-link active' : 'nav-link'} aria-current="page">
                                <span data-feather="shopping-cart"></span> Document
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/member/profile" className={location.pathname === '/member/profile' ? 'nav-link active' : 'nav-link'} aria-current="page">
                                <span data-feather="user"></span> Profile
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/member/other" className={location.pathname === '/member/other' ? 'nav-link active' : 'nav-link'} aria-current="page">
                                <span data-feather="user"></span> Other
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
};

export default NavbarComponent;