import MemberNavbarComponent from "./MemberNavbarComponent";
import MemberHeaderComponent from "./MemberHeaderComponent";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { getToken } from "../../service/authorize"

const LinkListComponent = (props) => {
    const [links,setLinks] = useState([]);
    const [state, setState] = useState({
        searchText: "",
    });

    const { searchText } = state;

    const searchTextChange  = (event) => {
        setState({ ...state, searchText: event.target.value });
    };


    const fetchData=() =>{
        axios
        .post(`${process.env.REACT_APP_API}member/getlinklist`,{  },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        })
        .then(response=>{
            // console.log(response.data.return)
            if(response.data.return!==null&&response.data.return!==""&&response.data.return!==undefined)
            {
                setLinks(response.data.return);
            }
        }).catch(err=>{
          Swal.fire(
            "Error",
            err.response.data.error,
            "error",
          );
        })
    }

    useEffect(()=>{
        fetchData();
    },[]);

    return(
        <div className="bg-light">
            <MemberHeaderComponent />
            <div className="container-fluid" >
                <MemberNavbarComponent />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-5">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="h2">Link List</h1>
                        <div className="btn-toolbar mb-2 mb-md-0">
                        </div>
                    </div>
                    <div className="panel">
                        <div className="body">
                            <div className="input-group-">
                                <div className="container-fluid"></div>
                                <div className="row ">
                                    <label className="col-form-label col-1" htmlFor="searchBox">Filter Text</label>
                                    <div className=" col-9"><input type="search" className="form-control" id="searchBox" placeholder="Filter Text..." value={searchText}  onChange={searchTextChange} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="myTable table hover mt-5">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Long URL</th>
                                <th>Short URL</th>
                            </tr>
                        </thead>
                        
                        <tbody id="tablehere">
                        { 
                            links.filter(link => (decodeURIComponent(link.long_url).includes(searchText)||decodeURIComponent(process.env.REACT_APP_URL+link.short_url).includes(searchText)) ).map((link,index) => (
                            // links.map((link,index)=>(
                                
                                <tr key={index} data-long={decodeURIComponent(link.long_url)} data-short={process.env.REACT_APP_URL+link.short_url}>
                                    <td>{(index+1)}</td>
                                    <td><a target='_blank' href={decodeURIComponent(link.long_url)}>{decodeURIComponent(link.long_url)}</a></td>
                                    <td><a target='_blank' href={process.env.REACT_APP_URL+link.short_url}>{process.env.REACT_APP_URL+link.short_url}</a></td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </main>
            </div>
        </div>
    )
}

export default LinkListComponent;