import { useNavigate } from "react-router-dom";
import { logout } from "../../service/authorize"
import '../../assets/css/member.css';

const NavbarComponent=()=>{
    const navigate = useNavigate();

    return(
        <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
            <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">Thsl Short Link Service</a>
            <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="form-control form-control-dark w-100 d-block d-sm-none" type="text" placeholder="Search" aria-label="Search"></div>
            <div className="navbar-nav">
                <div className="nav-item text-nowrap">
                    <a className="nav-link px-3 pointer_cursor" id="signout" onClick={()=>logout(()=>navigate("/"))}>Sign out</a>
                </div>
            </div>
        </header>
    );
};

export default NavbarComponent;