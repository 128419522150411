import FooterComponent from "../components/FooterComponent";
import { useState,useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";


const LinkToComponent = (props) => {

    const location = useLocation();
    const { pathname, search } = location; 
    const [state, setState] = useState({
        pageTo: ""
    });
    const { pageTo } = state;

    const redirectData=() =>{
        console.log(search);
        const to = pathname.replace("/", "");
        axios
        .get(`${process.env.REACT_APP_API}/${to}`,
        {
          headers: {
            Authorization: `Bearer `
          }
        })
        .then(response=>{
            if(response.data.success!==0)
            {
                if(response.data.result!==null&&response.data.result!==""&&response.data.result!==undefined)
                {
                    // console.log(response.data.result);
                    window.location.href = response.data.result;
                }
            }
            else
            {
                setState({ ...state, pageTo: "Page Not Found" });
            }

        }).catch(err=>{
            console.log(err.response.data);
            setState({ ...state, pageTo: "Page Not Found" });
        });
    }

    useEffect(()=>{
        redirectData();
    },[]);

    return (
        <div className="d-flex h-100 bg-dark">
            <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column text-center text-white min-h-max">
                <div className="container p-5">
                    <main className="px-3">
                        <h1>{pageTo}</h1>
                    </main>
                </div>
                <FooterComponent />
            </div>
        </div>
    );
}

export default LinkToComponent;
