import {Link,useLocation} from "react-router-dom";
import { getUserName } from "../service/authorize"

const NavbarComponent=()=>{
    const location = useLocation();
    return(
        <header className="mb-auto">
            <div>
                <h3 className="float-md-start mb-0">TH Short Link</h3>
                <nav className="nav nav-masthead justify-content-center float-md-end">
                    <Link to="/" className={location.pathname === '/' ? 'nav-link active' : 'nav-link'} aria-current="page">Home</Link>
                    <Link to="/qrcode"  className={location.pathname === '/qrcode' ? 'nav-link active' : 'nav-link'} aria-current="page">QRCode</Link>
                    <Link to="/changelang"  className={location.pathname === '/changelang' ? 'nav-link active' : 'nav-link'} aria-current="page">EN? to TH</Link>
                    {
                        !getUserName() ? (
                            <a href="#" className="nav-link" data-bs-toggle="modal" data-bs-target="#loginForm">Login</a>
                        ) : (
                            <div></div>
                        )
                    }
                </nav>
            </div>
        </header>

    );
};

export default NavbarComponent;